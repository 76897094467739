<template>
    <div style="height: 100%">
        <el-container>
            <el-header
                style="
                    text-align: right;
                    font-size: 12px;
                    background-color: #fff;
                "
            >
                <HEADER :nav="routes" />
            </el-header>
            <el-main
                :style="{
                    padding: $route.name == 'edit' ? '0' : '20px',
                    backgroundColor: $route.meta.black == 'black' ? '#000' : '',
                }"
                ><router-view></router-view
            ></el-main>
        </el-container>
    </div>
</template>


<script>
import { video } from "@/utils/router";
import HEADER from "@/components/top";
import LEFT_NAV from "@/components/left_nav";
import routes from "@/router/make";
export default {
    components: {
        HEADER,
        // LEFT_NAV,
    },
    data() {
        return {
            nav: [],
            routes,
        };
    },
    methods: {},
    mounted() {},
    created() {
        // console.log(video);
        this.nav = video;
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("CLEAR_FISS");
        next();
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-container {
    height: 100%;
}
</style>
